<template>
  <div class="components-catalog panel" v-if="components">
    <panel-legend />
    <div class="custom-scrollbar">
      <draggable
        v-model="components"
        class="drag-drop-elements"
        :options="dragOptions"
      >
        <div
          v-for="(component, index) in components"
          :key="index"
          class="components-placeholder column"
        >
          <img :src="src(component.icon)" />
          <span>{{ $t(component.longname) }}</span>
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import PanelLegend from "@/components/BackOffice/PanelLegend.vue";
import Templates from "@/assets/BackOfficeAssets/Component-templates.json";
export default {
  name: "ComponentsCatalog",
  components: {
    draggable,
    "panel-legend": PanelLegend,
    Templates,
  },
  computed: {
    components: {
      get() {     
         let temp =  Templates.filter((item)=>{
          return item.type !='group-header'
        })
        return temp
     
      },
      set() {},
    },
    dragOptions() {
      return {
        group: { name: "components", pull: "clone", put: false },
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    src(icon) {
      switch (icon) {
        case 1:
          return require("@/assets/BackOfficeAssets/1.svg");
        case 2:
          return require("@/assets/BackOfficeAssets/2.svg");
        case 3:
          return require("@/assets/BackOfficeAssets/3.svg");
        case 4:
          return require("@/assets/BackOfficeAssets/4.svg");
        case 5:
          return require("@/assets/BackOfficeAssets/5.svg");
        case "video":
          return require("@/assets/BackOfficeAssets/video.svg");
        case "map":
          return require("@/assets/BackOfficeAssets/map.svg");
        case "lesson":
          return require("@/assets/BackOfficeAssets/map.svg");
        case "global-connections":
          return require("@/assets/BackOfficeAssets/map.svg");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.components-catalog {
  width: 264px;
  background: #f5f5f5;
  margin-left: 16px;
  .custom-scrollbar {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 200px);
  }
  padding-bottom: 24px;
}
.components-container {
  .ghost {
    box-sizing: border-box;
    max-height: 1px;
    width: 542px;
    border: 4px solid #3a4dfd;
    margin: 0 auto;

    img,
    span {
      display: none;
    }
  }
}

.components-placeholder {
  margin-left: 16px;
  margin-top: 8px;
  height: 104px;
  width: 264px;
  cursor: pointer;
  flex-direction: column;

  img {
    height: 72px;
    width: 144px;
  }
  span {
    margin-top: 4px;
    height: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto";
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 16px;
  }
}
</style>
