var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.components
    ? _c(
        "div",
        { staticClass: "components-catalog panel" },
        [
          _c("panel-legend"),
          _c(
            "div",
            { staticClass: "custom-scrollbar" },
            [
              _c(
                "draggable",
                {
                  staticClass: "drag-drop-elements",
                  attrs: { options: _vm.dragOptions },
                  model: {
                    value: _vm.components,
                    callback: function($$v) {
                      _vm.components = $$v
                    },
                    expression: "components"
                  }
                },
                _vm._l(_vm.components, function(component, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "components-placeholder column"
                    },
                    [
                      _c("img", { attrs: { src: _vm.src(component.icon) } }),
                      _c("span", [_vm._v(_vm._s(_vm.$t(component.longname)))])
                    ]
                  )
                })
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }