<template>
    <div class="story-builder builder custom-scrollbar">
        <component :is="currentSection"/>
    </div>    
</template>
<script>
import StoryBuilderLayoutSection from '@/components/BackOffice/StoryBuilder/StoryBuilderLayoutSection.vue'
import StoryBuilderContentSection from '@/components/BackOffice/StoryBuilder/StoryBuilderContentSection.vue'
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'

export default {
    name: 'StoryBuilder',
    mixins:[BuilderMixin], 
    components:{
        'layout-section': StoryBuilderLayoutSection, 
        'content-section': StoryBuilderContentSection
    },
    created(){
        this.isLoadingInformation = true
        this.$store.commit('loadUnitStoryBuilderComponents', null)
        this.$store.dispatch('loadUnitStoryBuilderComponents', this.$route.params.unitstoryId)
            .finally(()=> this.isLoadingInformation = false)
        this.$store.commit('updateSwitchButtonDisplayStatus', true)
    }

}
</script>
<style lang="less" scoped>
    .story-builder{
        margin-top: 104px;
        height: calc(100vh - 104px);
    }
</style>



