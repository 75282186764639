<template>
  <div class="layout-section flex-container" v-cloak>
    <div class="components-container" v-cloak>
      <div class="lesson-banner-component flex-container">
        <span>{{ $t("storyBanner") }}</span>
      </div>
      <draggable
        class="draggable-section"
        v-model="components"
        :options="dragOptions"
        :move="onMove"
        @change="onChange"
      >
        <transition-group name="flip-list" tag="ul">
          <li v-for="(component, index) in components" :key="component.id">
            <story-component
              :component="component"
              :componentCount="index + 1"
            />
          </li>
        </transition-group>
      </draggable>
    </div>
    <components-catalog />
  </div>
</template>
<script>
import StoryComponentLayout from "@/components/BackOffice/StoryBuilder/StoryComponentLayout.vue";
import ComponentsCatalog from "@/components/BackOffice/LayoutComponents/ComponentsCatalog.vue";
import LayoutSectionMixin from "@/components/BackOffice/Mixins/LayoutSectionMixin.js";
import Factory from "@/utils/ContentFactory.js";
export default {
  name: "StoryLayoutSection",
  mixins: [LayoutSectionMixin],
  components: {
    "story-component": StoryComponentLayout,
    "components-catalog": ComponentsCatalog,
  },
  methods: {
    onChange({ added, moved }) {
      if (added) {
        let payload = {};
        let paragraph = new Factory.CreateParagraph(
          added.newIndex + 1,
          added.element
        );
        paragraph.unitstoryId = parseInt(this.$route.params.unitstoryId);
        payload.unitstoryId = parseInt(this.$route.params.unitstoryId);
        payload.order = paragraph.order;
        payload.paragraph = paragraph;
        this.$store.dispatch("createUnitStoryBuilderComponent", payload);
      }
      if (moved) {
        let component = this.components.find((e) => e.id === moved.element.id);
        this.$store.dispatch("reoderUnitStoryComponent", component);
      }
    },
  },
  computed: {
    components: {
      get() {
        return this.$store.state.unitStoryBuilderComponents;
      },
      set(value) {
        let components = value.filter((e) => e.id);
        this.$store.commit("updateUnitStoryComponentsOrder", components);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.layout-section {
  flex-direction: row;
  .components-container {
    &::-webkit-scrollbar {
      display: none;
    }
    .lesson-banner-component {
      margin-left: 0;
      span {
        text-transform: uppercase;
      }
    }
  }

  & /deep/ .drag-drop-elements {
    // height: calc(100vh - 166px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.components-catalog {
  height: calc(100vh - 110px);
}
</style>
